@media only screen and (min-width: 768px) {
    .modal-dialog {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        min-width: 80dvw;
    }

    .modal-body {
        display: flex;
    }

    .popup-img {
        width: 45%;
    }

    #popup-content h2 {
        position: relative;
        bottom: 10%;
    }

    #popup-body {
        display: flex;
    }

}

@media only screen and (max-width: 768px) {
    /* .modal-dialog {
    top: 15%;
} */

    #popup-content h2 {
        font-size: 28px !important;
    }

    #popup-content p {
        font-size: 12px !important;
    }
}

.close-button {
    background: transparent;
    color: #fff;
    border: none;
    font-size: 2rem;
    position: relative;
    left: 95%;
}

.modal-content {
    min-width: 50dvw;
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%) !important;
    border-radius: 15px !important;
}

#success-msg {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    bottom: 35px;
}

#success-msg p {
    color: #fff;
    font-size: 32px;
}

#popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    border: none;
    border-radius: 70px;
    padding: 10px;
    width: 300px;
    margin: 5px !important;
    text-align: center;
}

input::placeholder {
    text-align: center;
}

#btn {
    background-color: black;
    color: #fff;
    margin-top: 5px;
    border: 2px solid #fff;
    transition: all 0.4s 0s;
    margin-bottom: 15px;
}

#btn:hover {
    color: #f0869f;
}

#popup-content p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
}

#intro-txt {
    position: relative;
    bottom: 6%;
}

.popup-img {
    display: flex;
    justify-content: center;
    align-items: center;
}