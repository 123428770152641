html:lang(ar){
    #header{
        direction: rtl;
    }
    .title{
        direction: rtl;
    }
    select{
        direction: rtl;
    }
    .navbar-wrap{
        display: flex;
        flex-grow: 1;
        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 140px;
            li{
                display: block;
                position: relative;
                margin-right: 65px;
            }
        }
    }
    .logo img{
        height: 55px;

    }
    .navbar-wrap > ul > li > a {
        font-size: larger;
        font-weight: 600;
        // text-transform: uppercase;
        color: #0c0c0c;
        padding: 45px 0;
        display: block;
        line-height: 1;
        position: relative;
        z-index: 1;
        letter-spacing: 1px;
      }
     
      .choose-item,#about,#sales > div > div > div.row.align-items-center.justify-content-center,.white-marker{
        direction: rtl;
      }
    //   .white-marker  li span {
    //     position: relative;
    //     right: -10px;
    //   }
      
  
}